<template>
  <v-card
    :class="`${ fileName !== this.$t('file_empty') ? 'border-green' : ''} border-radius`"
    outlined
  >
    <v-card-title class="subtitle-2 font-weight-bold v-card__title">
      {{ fileInfo.name }}
    </v-card-title>
    <v-card-subtitle class="subtitle-2 font-weight-bold v-card__title">
      Documento: {{ fileInfo.mandatory ? 'Requerido' : 'No requerido' }}
    </v-card-subtitle>
    <v-card-text class="d-flex">
      <span>{{ fileName }}</span>
    </v-card-text>
    <v-card-actions class="mt-auto">
      <div class="v-col col-6 pa-0">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              :class="`ml-2 ${disabled ? 'grey lighten-2' : 'secondary'}`"
              :disabled="disabled"
              icon
              v-on="on"
            >
              <v-icon
                color="primary"
                size="1.2em"
                light
                @click="dialog = true"
              >
                fas fa-cloud-upload
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('upload_file') }}</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              :class="`ml-2 ${!fileInfo.fileUrl || disabled? 'grey lighten-2' : 'secondary'}`"
              :disabled="!fileInfo.fileUrl || disabled"
              icon
              v-on="on"
            >
              <v-icon
                color="primary"
                :disabled="disabled"
                size="1.2em"
                light
                @click="() => { showConfirmationModal = true }"
              >
                fal fa-trash-alt
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('delete_file') }}</span>
        </v-tooltip>
      </div>
      <div class="v-col col-6 text-right pa-0">
        <v-icon
          v-if="fileInfo.encrypted && (fileName !== null)"
          color="amber"
          class="ml-3 d-inline"
          longer
        >
          fa-lock
        </v-icon>
        <v-chip
          :class="`ma-2 white--text ${ fileInfo.fileUrl !== null && fileInfo.fileUrl !== '' ? 'green_button' : 'grey lighten-1'}`"
        >
          {{ fileInfo.fileUrl !== null && fileInfo.fileUrl !== '' ? 'Cargado' : 'Pendiente' }}
        </v-chip>
      </div>
    </v-card-actions>
    <confirmation-modal
      :show-modal="showConfirmationModal"
      title-modal="¿Está seguro de eliminar este archivo?"
      message-modal="Recuerda que después de confirmar la acción no se recupera el archivo"
      agree-text-bottom="Eliminar"
      disagree-text-bottom="Cancelar"
      @resolve="resolveConfirmationModalDeleteFile"
    />
    <v-dialog
      v-model="dialog"
      :persistent="persistend"
      max-width="600px"
    >
      <v-card>
        <v-card-title
          class="d-flex justify-center"
        >
          {{ $t('modal.uploadFile.title') }}
        </v-card-title>
        <v-card-subtitle
          class="headline d-flex justify-center"
        >
          {{ fileInfo.name }}
        </v-card-subtitle>
        <v-container>
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Tamaño máximo {{ fileInfo.maxFileSize }} MB: </v-list-item-title>
                  <v-list-item-title>Extensión permitida : {{ fileInfo.extension }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <ValidationObserver
                ref="obs"
                v-slot="{on, handleSubmit }"
              >
                <v-form
                  @submit.prevent="handleSubmit(sendAttachment)"
                >
                  <v-col
                    cols="12"
                  >
                    <ValidationProvider
                      ref="file"
                      v-slot="{ errors }"
                      :rules="`required|file-extensions:${getExtension(fileInfo)}|filename`"
                      name="de archivo a cargar"
                      vid="file"
                    >
                      <v-file-input
                        :id="`file_${fileInfo.id}`"
                        :ref="`file_${fileInfo.id}`"
                        v-model="uploadFile"
                        :error-messages="errors"
                        :accept="mimeTypes"
                        label="Cargar archivo"
                        v-on="on"
                      />
                    </ValidationProvider>
                  </v-col>
                  <v-col
                    v-if="fileInfo.encrypted"
                    cols="12"
                  >
                    <p>{{ $t('modal.uploadFile.upload_encrypt_file') }}</p>
                    <div>
                      <ValidationProvider
                        ref="clave"
                        v-slot="{ errors }"
                        rules="required"
                        name="de clave de cifrado"
                        vid="encrypt"
                      >
                        <v-text-field
                          id="clave"
                          v-model="passworEconomic"
                          :error-messages="errors"
                          :label="$t('label_password_encrypted').toLowerCase()"
                          name="clave"
                          type="password"
                          outlined=""
                          rounded
                          v-on="on"
                        />
                      </ValidationProvider>
                    </div>
                  </v-col>
                  <v-row dense>
                    <v-col
                      cols="12"
                    >
                      <v-card-actions>
                        <v-col
                          cols="6"
                        >
                          <v-btn
                            depressed
                            large
                            type="submit"
                            color="primary"
                            block
                            :disabled="$refs.obs && (($refs.obs.errors.file.length !== 0) || (fileInfo.encrypted && $refs.obs.errors.encrypt.length !== 0))"
                          >
                            <span class="btn_span">{{ $t('confirm') }}</span>
                          </v-btn>
                        </v-col>
                        <v-col
                          cols="6"
                        >
                          <v-btn
                            depressed
                            large
                            color="primary"
                            block
                            @click="disagre"
                          >
                            <span class="btn_span">{{ $t('cancel') }}</span>
                          </v-btn>
                        </v-col>
                      </v-card-actions>
                    </v-col>
                  </v-row>
                </v-form>
              </ValidationObserver>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <v-col
      v-if="progressBar"
      cols="12"
    >
      <v-progress-linear
        :value="uploadPercentage"
        color="green_button"
        height="15"
        class=".d-none"
      >
        <strong>{{ uploadPercentage }}%</strong>
      </v-progress-linear>
    </v-col>
  </v-card>
</template>

<script>
import ConfirmationModal from '@/components/uiComponents/modals/confirmationModal/ConfirmationModal'
import { mapState } from 'vuex'
import handleErrorMixin from '../../../../mixins/handleErrorMixin'
import { UNPROCESSABLE_ENTITY, CONFLICT, NOT_FOUND, BAD_REQUEST } from '@/shared/constants/httpStatus'
import { CREATE_OR_DELETE_DOCUMENT, DOCUMENT_TYPES } from './data/uploadFile'
import { SHOW_ALERT } from '../../../../store/mutations-types'

export default {
  name: 'CardUploadFile',
  components: {
    ConfirmationModal
  },
  mixins: [handleErrorMixin],
  props: {
    disabled: {
      type: Boolean,
      default: false,
      description: 'Indica si la card no esta inhabilitada'
    },
    fileInfo: {
      type: Object,
      required: true,
      description: 'Informacion del archivo a cargar'
    },
    tenderId: {
      type: Number,
      required: true,
      description: 'tender id del projecto'
    },
    encrypted: {
      type: Boolean,
      default: false,
      description: 'Indica si el archivo se debe encriptar'
    },
    stageId: {
      type: Number,
      required: true,
      description: 'id de la etapa'
    }
  },
  data () {
    return {
      showConfirmationModal: false,
      showUploadDocument: false,
      dialog: false,
      uploadFile: undefined,
      uploadPercentage: 0,
      progressBar: false,
      passworEconomic: null,
      mimeTypes: ['application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
      persistend: true
    }
  },
  computed: {
    /**
     * This property computed get userInfo objet that response back
     *
     * @author Andres Correa
     * @since 08/05/2020 6:20 PM
     * @version 1.0.0
     */
    ...mapState({ userInfo: (state) => state.security.userInfo }),
    fileName () {
      return this.fileInfo.fileUrl !== null && this.fileInfo.fileUrl !== '' ? this.fileInfo.fileUrl.split('/')[this.fileInfo.fileUrl.split('/').length - 1] : (this.uploadFile ? this.uploadFile.name : this.$t('file_empty'))
    }
  },
  watch: {
    dialog (value) {
      if (value && this.fileInfo.encrypted) {
        this.$nextTick(() => { this.$refs.obs.validate() })
      }
    }
  },
  methods: {
    getExtension ({ extension }) {
      return DOCUMENT_TYPES[extension]
    },
    /**
     * This method send file to back with form
     *
     * @author Andres Correa
     * @since 08/05/2020 3:20 PM
     * @version 1.0.0
     */
    sendAttachment () {
      this.progressBar = true
      this.dialog = false
      var formData = new FormData()
      formData.append('file', this.uploadFile)
      formData.append('userId', this.userInfo.businessUserDetailDTO.userId)
      formData.append('documentId', this.fileInfo.id)
      formData.append('keyPassword', this.passworEconomic)
      formData.append('stageId', this.stageId)
      this.axios.post(CREATE_OR_DELETE_DOCUMENT, formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100))
          }.bind(this)
        })
        .then((response) => {
          this.progressBar = false
          this.fileInfo.fileUrl = this.uploadFile.name
          this.$emit('upload-file', this.uploadFile.name)
          this.$store.commit(SHOW_ALERT, {
            type: 'success',
            text: this.$t('upload_success'),
            show: true
          })
        }).catch((error) => {
          this.fileInfo.fileUrl = null
          this.progressBar = false
          this.uploadFile = null
          if (error.response.status === UNPROCESSABLE_ENTITY) {
            this.$store.commit(SHOW_ALERT, {
              type: 'warning',
              text: this.$t('error_file'),
              show: true
            })
            return
          }
          if (error.response.status === CONFLICT) {
            this.$store.commit(SHOW_ALERT, {
              type: 'warning',
              text: this.$t('wrong_encrypted'),
              show: true
            })
            return
          }
          if (error.response.status === NOT_FOUND) {
            this.$store.commit(SHOW_ALERT, {
              type: 'error',
              text: this.$t('url_wrong'),
              show: true
            })
            return
          }
          if (error.response.status === BAD_REQUEST) {
            this.$store.commit(SHOW_ALERT, {
              type: 'warning',
              text: error.response.data.errorMessage,
              show: true
            })
            return
          }
          this.$_handleErrorMixin_generateError(error)
        })
      // this.$emit('upload-file', file.name)
    },
    /**
     * This method validate response for delete file from form or cancel
     *
     * @author Andres Correa
     * @since 08/05/2020 5:20 PM
     * @version 1.0.0
     */
    resolveConfirmationModalDeleteFile (response) {
      this.showConfirmationModal = false
      if (response) {
        // agree
        this.deleteFile()
      } else {
        // desagree
      }
    },
    /**
     * This method delete file
     *
     * @author Andres Correa
     * @since 08/05/2020 5:00 PM
     * @version 1.0.0
     */
    deleteFile () {
      this.dialog = false
      this.axios.delete(CREATE_OR_DELETE_DOCUMENT,
        {
          params: {
            stageId: this.stageId,
            userId: this.userInfo.businessUserDetailDTO.userId,
            documentId: this.fileInfo.id
          }
        })
        .then((response) => {
          this.fileInfo.fileUrl = null
          this.uploadFile = undefined
          this.$emit('upload-file', null)
          this.$store.commit(SHOW_ALERT, {
            type: 'success',
            text: this.$t('delete_success'),
            show: true
          })
        }).catch((error) => {
          if (error.response.status === NOT_FOUND) {
            this.$store.commit(SHOW_ALERT, {
              type: 'warning',
              text: this.$t('wrong_find_file'),
              show: true
            })
            this.fileInfo.fileUrl = null
            this.progressBar = false
            return
          }
          this.$_handleErrorMixin_generateError(error)
        }).finally(() => {
          this.uploadPercentage = 0
        })
    },
    /**
     * This method empty file fileUrl when user clic en cancel
     *
     * @author Andres Correa
     * @since 08/05/2020 5:00 PM
     * @version 1.0.0
     */
    disagre () {
      this.dialog = false
      this.uploadFile = undefined
    }
  }
}
</script>

<style lang="scss">
.border-green {
  border: 3px solid #76CC14 !important;
}
.border-radius {
  border-radius: 10px !important;
}
.btn_span {
  color: #797869;
}
.v-card__title {
  word-break: normal;
}
</style>
<i18n>
{
  "es": {
    "cancel": "Cancelar",
    "confirm": "Confirmar",
    "delete_file": "Eliminar archivo",
    "upload_file": "Cargar archivo",
    "error_file": "La extensión del archivo no es la correcta o el archivo podría estar dañado",
    "wrong_encrypted": "La contraseña ingresada es incorrecta",
    "wrong_find_file": "El archivo a eliminar no existe, intente nuevamente",
    "delete_success": "Archivo eliminado exitosamente",
    "upload_success": "Archivo cargado exitosamente",
    "file_empty" : "Archivo sin cargar",
    "url_wrong": "No existe el recurso que ha sido solicitado",
    "label_password_encrypted": "Clave Kontratacion",
    "modal": {
      "uploadFile": {
        "title": "Cargar Archivo",
        "upload_encrypt_file": "Cargar archivo cifrado"
      }
    }
  }
}
</i18n>
