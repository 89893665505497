<template>
  <v-dialog
    v-model="sendPassword"
    :persistent="persistent"
    max-width="510"
  >
    <v-card class="pa-8">
      <v-card-title class="d-flex justify-center pb-0 pt-8 subtitle-2 text-center">
        {{ titleModal }}
      </v-card-title>
      <ValidationObserver
        ref="obs"
        v-slot="{on, handleSubmit}"
      >
        <v-col
          cols="12"
        >
          <div>
            <ValidationProvider
              v-slot="{ errors }"
              :rules="ConfirmationPasswordValidation.encrypted"
              name="clave"
              vid="encrypt"
            >
              <v-text-field
                id="fieldEncryptedForm"
                v-model="encryptedForms"
                :error-messages="errors"
                label="clave"
                name="encrypted"
                type="password"
                outlined
                rounded
                v-on="on"
              />
            </ValidationProvider>
          </div>
        </v-col>
        <v-card-actions class="justify-center justify-space-around mt-4">
          <v-btn
            color="primary secondary--text px-6"
            large
            @click="handleSubmit(agree)"
          >
            {{ agreeTextBottom }}
          </v-btn>
          <v-btn
            color="primary secondary--text px-6"
            large
            @click="disagree"
          >
            {{ disagreeTextBottom }}
          </v-btn>
        </v-card-actions>
      </ValidationObserver>
    </v-card>
  </v-dialog>
</template>
<script>
import ConfirmationPasswordValidation from './data/confirmationPasswordValidation'
export default {
  name: 'ConfirmationModal',
  props: {
    sendPassword: {
      type: Boolean,
      required: true,
      description: 'Controla cuando muestra la modal'
    },
    titleModal: {
      type: String,
      required: true,
      description: 'Mensaje de la modal'
    },
    agreeTextBottom: {
      type: String,
      required: true,
      description: 'Mensaje botón para aceptar'
    },
    disagreeTextBottom: {
      type: String,
      required: true,
      description: 'Mensaje botón para cancelar'
    },
    persistent: {
      type: Boolean,
      default: true,
      description: 'La modal no se cierra al presionar fuera de ella'
    }
  },
  data () {
    return {
      ConfirmationPasswordValidation,
      dialog: false,
      encryptedForms: null
    }
  },
  methods: {
    agree () {
      this.$emit('resolve', this.encryptedForms)
    },
    disagree () {
      this.encryptedForms = ''
      this.$emit('resolve', null)
    }
  }
}
</script>
