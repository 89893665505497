
const PUBLIC_BASE_URL = process.env.VUE_APP_API_URL

export const URL_GET_PROJECT_DETAILS = `${PUBLIC_BASE_URL}service/tender/tender/detail`
export const URL_FINISH_AND_SAVE_PROPOSAL = `${PUBLIC_BASE_URL}service/proposal`

export const TYPES_FILES = {
  digitalFormDocument: 'DFD',
  offerMaintananceWarrantyDocument: 'OMD',
  anticorruptionAndPenaltiesAcceptanceLetterDocument: 'AAL',
  integrityDeclarationDocument: 'IDD',
  apcaModelLetterDocument: 'APCA',
  legalCapacityDocument: 'LCA',
  tec2aDocument: 'TEC2A',
  tec2bDocument: 'TEC2B',
  tec4Document: 'TEC4',
  tec5Document: 'TEC5',
  tec6Document: 'TEC6',
  economicInformation: 'EID',
  economicProposalDocument: 'EPD'
}

export const PROPOSAL_TENDER_STATUS = {
  finished: 'FI',
  opened: 'OP',
  in_progress: 'IP'
}
