<template>
  <v-container>
    <v-row class="pt-7">
      <v-col class="d-flex">
        <h1 class="headline font-weight-bold text-uppercase my-auto">
          {{ tender.tenderName }}
        </h1>
      </v-col>
      <v-col
        class="col-12 col-sm-5 col-md-3 col-xl-2"
        align="right"
      >
        <time-remaining
          v-if="Object.keys(tender).lenght !== 0"
          :end-time="tender.tenderDueDate"
        />
      </v-col>
    </v-row>
    <v-divider class="my-3 primary" />
    <v-row no-gutters>
      <v-col>
        <h2 class="headline text-uppercase grey--text">
          {{ $t('basic_data') }}
        </h2>
      </v-col>
    </v-row>
    <v-divider class="my-3" />
    <v-row>
      <v-col
        cols="12"
        lg="9"
      >
        <v-container class="py-0">
          <v-row class="mb-3">
            <span class="col col-12 col-md-2 pa-0 grey--text">
              {{ $t('contractor') }}:
            </span>
            <span
              class="col col-12 col-md-10 pa-0"
            >
              {{ tender.tenderOwner }}
            </span>
          </v-row>
          <v-row>
            <span class="col col-12 col-md-2 pa-0 grey--text">
              {{ $t('object_to_contract') }}:
            </span>
            <span class="col col-12 col-md-10 pa-0">
              {{ tender.tenderDescription }}
            </span>
          </v-row>
        </v-container>
      </v-col>
      <v-col class="col-12 col-lg-3">
        <v-container class="py-0 d-flex d-lg-block">
          <v-row class="mb-lg-3">
            <span class="col col-12 col-sm-6 col-lg-6 pa-0 grey--text text-lg-right">
              {{ $t('close_date') }}:
            </span>
            <span class="col col-12 col-md-6 pa-0 my-auto text-lg-right">
              {{ $_formatDateMixin_formatDate(tender.tenderDueDate) }}
            </span>
          </v-row>
          <v-row>
            <span class="col col-12 col-sm-6 col-lg-6 pa-0 grey--text text-lg-right">
              {{ $t('close_time') }}:
            </span>
            <span class="col col-12 col-md-6 pa-0 my-auto text-lg-right">
              {{ $_formatDateMixin_formatTime(tender.tenderDueDate) }}
            </span>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-row
      class="mt-5"
      no-gutters
    >
      <v-col>
        <h2 class="headline text-uppercase grey--text">
          {{ $t('data_proponent') }}
        </h2>
      </v-col>
    </v-row>
    <v-divider class="my-3" />
    <v-row>
      <v-col
        class="d-md-flex"
        cols="12"
        md="6"
      >
        <div class="grey--text">
          {{ $t('name_proponent') }}:
        </div>
        <div class="ml-md-2">
          {{ userInfo.businessUserDetailDTO.businessName }}
        </div>
      </v-col>
      <v-col
        class="d-md-flex"
        cols="12"
        md="6"
      >
        <div class="grey--text">
          {{ $t('members') }}:
        </div>
        <div class="ml-md-2">
          <ul class="unstyled">
            <li
              v-for="(member, index) in userInfo.businessUserDetailDTO.members"
              :key="index"
              class="pb-5"
            >
              {{ member }}
            </li>
          </ul>
        </div>
      </v-col>
    </v-row>
    <v-row
      v-if="tender !== {}"
    >
      <v-col
        v-if="tender.stageResponseList && tender.stageResponseList.length > 0"
        cols="12"
      >
        <!-- For por etapas -->
        <v-expansion-panels
          ref="contentPanels"
          v-model="panel"
          flat
          :disabled="disabledAllProposal"
        >
          <!-- Expansion panel SubmissionFormFiles for por secciones -->
          <template v-for="stagesDetail in tender.stageResponseList">
            <v-expansion-panel
              v-if="stagesDetail.showStage"
              :key="stagesDetail.id"
              flat
            >
              <v-expansion-panel-header>
                Etapa: {{ stagesDetail.name }}
                <div>
                  <v-tooltip right>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        :color="stagesDetail.complete ? 'green_button' : 'error'"
                        class="ml-5 d-inline-block"
                        v-on="on"
                      >
                        {{ stagesDetail.complete ? 'fa-check-circle' : 'fa-exclamation-triangle' }}
                      </v-icon>
                    </template>
                    <span>{{ stagesDetail.complete ? 'ETAPA COMPLETA' : 'ETAPA INCOMPLETA' }}</span>
                  </v-tooltip>
                </div>
                <template v-slot:actions>
                  <v-icon color="secondary">
                    fa-angle-down
                  </v-icon>
                </template>
              </v-expansion-panel-header>
              <v-divider />
              <!-- RECORRER DOCUMENTO -->
              <v-expansion-panel-content>
                <v-expansion-panels>
                  <template v-for="sectionsDetails in stagesDetail.sectionResponseList">
                    <v-expansion-panel
                      v-if="sectionsDetails.showSection"
                      :key="sectionsDetails.id"
                    >
                      <v-expansion-panel-header>
                        Sección: {{ sectionsDetails.name }}
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-container>
                          <v-row>
                            <v-col
                              v-for="document in sectionsDetails.documentResponseList"
                              :key="document.id"
                              class="d-flex"
                              cols="12"
                              md="6"
                              lg="4"
                            >
                              <card-upload-file
                                class="d-flex flex-column align-stretch col-12"
                                :file-info="document"
                                :tender-id="tender.id"
                                :stage-id="stagesDetail.id"
                                :encrypt-file="false"
                                :disabled="validateProposalActive"
                                @upload-file="(nameFile) => updateFile(nameFile, document, stagesDetail)"
                              />
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </template>
                </v-expansion-panels>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </template>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-end">
      <v-btn
        color="grey lighten-1 white--text"
        class="mt-12 mb-4 mr-4"
        @click="cancel"
      >
        {{ $t('cancel') }}
      </v-btn>
      <v-btn
        v-if="validateAllFormsProposal === false"
        color="primary secondary--text"
        class="mt-12 mb-4 mr-4 mr-md-0"
        :disabled="tender.stageResponseList && !tender.stageResponseList.every(status => status.complete) || tender.tenderUserProposalStatus === 'FI'"
        @click="() => { showConfirmtionSaveAndSendModal = true }"
      >
        {{ $t('finish_and_save') }}
      </v-btn>
    </v-row>
    <confirmation-modal
      :show-modal="showConfirmtionSaveAndSendModal"
      title-modal="¿Está seguro de finalizar y enviar la información?"
      message-modal="Con su aceptación su propuesta estará firmada digitalmente y por tanto estará viculada a un tiempo '(hh:mm:ss)' y una fecha '(dd:mm:aa)'. Su propuesta así enviada tendrá los atributos que la Ley colombiana otorga a los documentos firmados digitalmente y por tanto la propuesta (como un todo) será auténtica, no podrá ser repudiada y será confidencial."
      agree-text-bottom="Confirmar"
      disagree-text-bottom="Cancelar"
      @resolve="resolveConfirmationSaveAndSend"
    />
    <confirmation-password
      :send-password="showEncryptionPasswordModal"
      title-modal="Por favor ingrese su clave de cifrado"
      agree-text-bottom="Aceptar"
      disagree-text-bottom="Cancelar"
      @resolve="resolveEncryptionPasswordModal"
    />
    <confirmation-modal
      :show-modal="showProposalSavedModal"
      title-modal="La información ha sido guardada y enviada exitosamente"
      message-modal="Recibirá la constancia de envío al correo registrado"
      agree-text-bottom="Entendido"
      :show-disagree-button="false"
      icon="far fa-check-circle"
      icon-color="success"
      @resolve="resolveProposalSavedModal"
    />
  </v-container>
</template>
<script>
import { URL_GET_PROJECT_DETAILS, TYPES_FILES, PROPOSAL_TENDER_STATUS, URL_FINISH_AND_SAVE_PROPOSAL } from './data/projectDetails'
import CardUploadFile from '@/components/uiComponents/cards/cardUploadFile/CardUploadFile'
import ConfirmationModal from '@/components/uiComponents/modals/confirmationModal/ConfirmationModal'
import ConfirmationPassword from '@/components/uiComponents/modals/confirmationPassword/ConfirmationPassword'
import { mapState } from 'vuex'
import Vue from 'vue'
import handleErrorMixin from '../../../../../../mixins/handleErrorMixin'
import { ADD_COUNTER_REQUEST, SUBTRACT_COUNTER_REQUEST, SHOW_ALERT } from '@/store/mutations-types'
import { BAD_REQUEST, CONFLICT } from '@/shared/constants/httpStatus'
import TimeRemaining from '@/components/uiComponents/timeRemaining/TimeRemaining'
import FormatDateMixin from '@/mixins/formatDateMixin'
import store from '@/store'

export default {
  name: 'ProjectDetails',
  components: {
    CardUploadFile,
    ConfirmationModal,
    ConfirmationPassword,
    TimeRemaining
  },
  mixins: [
    handleErrorMixin,
    FormatDateMixin
  ],
  beforeRouteEnter (to, from, next) {
    if (!to.params.tenderId) {
      next({ name: 'projects' })
    } else {
      store.commit(ADD_COUNTER_REQUEST)
      Vue.axios
        .get(`${URL_GET_PROJECT_DETAILS}`, {
          params: {
            userId: store.state.security.userInfo.businessUserDetailDTO.userId,
            tenderId: to.params.tenderId
          }
        })
        .then((response) => {
          store.commit(SUBTRACT_COUNTER_REQUEST)
          next(vm => vm.setTenderInfo(response))
        }).catch((error) => {
          if (error.response && error.response.status && error.response.status === BAD_REQUEST) {
            next(vm => vm.$_handleErrorMixin_redirectPage(error, 'Home'))
            return
          }
          next(vm => vm.$_handleErrorMixin_generateError(error))
        })
    }
  },
  data () {
    return {
      tender: {},
      testReplace: false,
      TYPES_FILES,
      PROPOSAL_TENDER_STATUS,
      showConfirmtionSaveAndSendModal: false,
      showEncryptionPasswordModal: false,
      showProposalSavedModal: false,
      submissionForm: [],
      technicalForm: [],
      economicForm: [],
      disabledAllProposal: false,
      panel: [],
      disableButton: false,
      certificate: null
    }
  },
  computed: {
    /**
     * This property computed get userInfo objet that response back
     *
     * @author Andres Correa
     * @since 08/05/2020 6:20 PM
     * @version 1.0.0
     */
    ...mapState({ userInfo: (state) => state.security.userInfo }),
    validateAllFormsProposal () {
      return !(true)
    },
    validateProposalActive () {
      return (Object.keys(this.tender).length === 0 || this.tender.tenderUserProposalStatus === 'FI' || this.tender.tenderStatus === 'CL')
    }
  },
  watch: {
    'tender' (tender) {
      if (tender.id) {
        tender.stageResponseList.forEach(stage => {
          this.validateStage(stage)
          this.validateStageComplete(stage)
        })
      }
    }
  },
  methods: {
    /**
     * This method received the nameFile that's upload and call the method validateStageComplete
     *
     * @author Andres Correa
     * @since 12/08/2020 10:20 AM
     * @version 1.0.0
     */
    updateFile (nameFile, document, stagesDetail) {
      document.fileUrl = nameFile
      this.validateStageComplete(stagesDetail)
    },
    /**
     * This method validated a stage complete, documents Url is complete or that the document is not mandatory
     *
     * @author Andres Correa
     * @since 12/08/2020 10:20 AM
     * @version 1.0.0
     */
    validateStageComplete (stagesDetail) {
      let completeStage = stagesDetail.sectionResponseList.every(section => section.documentResponseList.every(document => document.fileUrl || !document.mandatory))
      this.$set(stagesDetail, 'complete', completeStage)
    },
    /**
     * This method validates that the section contains documents to be displayed
     *
     * @author Andres Correa
     * @since 13/08/2020 1:42 PM
     * @version 1.0.0
     */
    validateStage (stage) {
      var showStage = true
      if (stage && stage.sectionResponseList) {
        stage.sectionResponseList.forEach(section => this.validateCompleteDocuments(section))
        showStage = stage.sectionResponseList.some(section => section.showSection)
      }
      this.$set(stage, 'showStage', showStage)
    },
    /**
     * This method validates that the documents are uploaded
     *
     * @author Andres Correa
     * @since 13/08/2020 1:42 PM
     * @version 1.0.0
     */
    validateCompleteDocuments (section) {
      var showSection = true
      if (!section || !section.documentResponseList || section.documentResponseList.length === 0) {
        showSection = false
      }
      this.$set(section, 'showSection', showSection)
      return showSection
    },
    /**
     * This method set data from tender
     *
     * @author Andres Correa
     * @since 13/08/2020 10:20 AM
     * @version 1.0.0
     */
    setTenderInfo (response) {
      this.tender = response.data
    },
    /**
     * This method validated answer for get decision if save or cancel
     *
     * @author Andres Correa
     * @since 07/05/2020 2:20 PM
     * @version 1.0.0
     */
    resolveConfirmationSaveAndSend (response) {
      this.showConfirmtionSaveAndSendModal = false
      if (response) {
        this.showEncryptionPasswordModal = true
      }
    },
    resolveEncryptionPasswordModal (response) {
      this.showEncryptionPasswordModal = false
      if (response) {
        this.finishAndSend(response)
      }
    },
    resolveProposalSavedModal (response) {
      this.showProposalSavedModal = false
      this.$router.push({ name: 'Home' })
    },
    cancel () {
      this.$router.go(-1)
    },
    /**
     * This method save project and disabled forms
     *
     * @author Andres Correa
     * @since 07/05/2020 2:20 PM
     * @version 1.0.0
     */
    finishAndSend (password) {
      this.$store.commit(ADD_COUNTER_REQUEST)
      this.axios.post(`${URL_FINISH_AND_SAVE_PROPOSAL}`,
        { userId: this.userInfo.businessUserDetailDTO.userId, keyPassword: password, tenderId: this.$route.params.tenderId }
      ).then((response) => {
        this.showProposalSavedModal = true
        this.certificate = response.data
      }).catch((error) => {
        if (error.response.status === CONFLICT) {
          this.$store.commit(SHOW_ALERT, {
            type: 'warning',
            text: this.$t('wrong_encrypted'),
            show: true
          })
          return
        }
        if (error.response.status === BAD_REQUEST) {
          this.$store.commit(SHOW_ALERT, {
            type: 'warning',
            text: error.response.data.errorMessage,
            show: true
          })
          return
        }
        this.$_handleErrorMixin_generateError(error)
      }).finally(() => {
        this.$store.commit(SUBTRACT_COUNTER_REQUEST)
      })
    }
  }
}
</script>
<style lang="scss">
.card__upload-file {
  border-radius: 10px !important;
  border: 1px solid #76CC14 !important;
}
ul {
  list-style-type: none;
}
</style>
<i18n>
{
  "es": {
    "basic_data": "DATOS BÁSICOS DEL PROYECTO",
    "contractor": "CONTRATANTE",
    "object_to_contract": "OBJETO A CONTRATAR",
    "close_date": "FECHA DE CIERRE",
    "close_time": "HORA DE CIERRE",
    "data_proponent": "DATOS PROPONENTE",
    "name_proponent": "NOMBRE",
    "members": "INTEGRANTES",
    "form_submission": "INFORMACIÓN GENERAL Y DE ELEGIBILIDAD",
    "technical_proposal": "PROPUESTA TÉCNICA",
    "price_proposal": "PROPUESTA DE PRECIOS",
    "finish_and_save": "FINALIZAR Y ENVIAR",
    "cancel": "SALIR",
    "wrong_encrypted": "La contraseña ingresada es incorrecta"
  }
}
</i18n>
